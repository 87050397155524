import { ref, watch } from '@vue/composition-api'
import { isNavGroupActive } from '@core/layouts/utils'
import { useRouter } from "@/@core/utils/utils";
export default function useHorizontalNavMenuHeaderGroup(item) {
  // ------------------------------------------------
  // isOpen
  // ------------------------------------------------
  const isOpen = ref(false)
  const route = useRouter();
  watch(route.route, newVal => {
    if (isOpen.value)
      setTimeout(() => isOpen.value = false, 0)
  })
  const updateGroupOpen = val => {
    // eslint-disable-next-line no-use-before-define
    isOpen.value = val
  }

  // ------------------------------------------------
  // isActive
  // ------------------------------------------------
  const isActive = ref(false)

  const updateIsActive = () => {
    isActive.value = isNavGroupActive(item.children)
  }

  return {
    isOpen,
    isActive,
    updateGroupOpen,
    updateIsActive,
  }
}
