<template>
  <div>
    <li
      v-if="canViewVerticalNavMenuGroup(item)"
      class="nav-item has-sub"
      :class="{
        'active':isActive,
        'open': isOpen,
        'disabled': item.disabled,
        'sidebar-group-active': isActive,
      }"
      :id="item.id"
    >
      <div class="d-flex align-items-center" v-if="linkProps.to">
        <!-- Navigation Link (75% width) -->
        <div class="col-9 nav-item-col">
          <b-link
          
            v-bind="linkProps"
            :id="'href-'+item.id"
            class="d-flex align-items-center w-100"
            
          >
            <feather-icon
              style="
                width: 1.5em;
                height: 1.5em;
                min-width: 1.5em;
                background-size: 15px;
              "
              :icon="item.icon || 'CircleIcon'"
            />
            <span class="menu-title text-truncate">{{ t(item.title) }}</span>
            <b-badge
              v-if="item.tag"
              pill
              :variant="item.tagVariant || 'primary'"
              class="mr-1 ml-auto"
            >
              {{ item.tag }}
            </b-badge>
          </b-link>
        </div>

        <!-- Dropdown Trigger Link (25% width) -->
        <div
          class="col-3 nav-item-col drop-down-item"
          style="border-left: 2px solid rgba(122, 122, 122, 0.1)"
        >
          <b-link
            class="d-flex align-items-center justify-content-end"
            style="height: max-content"
            @click="() => updateGroupOpen(!isOpen)"
          >
            <feather-icon
              style="width: 15px; height: 15px"
              icon="ChevronDownIcon"
            />
          </b-link>
        </div>
      </div>
      <div v-else class="d-flex align-items-center">
       
        <!-- Navigation Link (75% width) -->
        <b-link
        style="padding-left: 0px;"
          class="d-flex align-items-center w-100 "
          @click="() => updateGroupOpen(!isOpen)"
        >
        <div class="col-9" >
          <feather-icon
              style="
                width: 1.5em;
                height: 1.5em;
                min-width: 1.5em;
                background-size: 15px;
              "
              :icon="item.icon || 'CircleIcon'"
            />
          <span class="menu-title text-truncate">{{ t(item.title) }}</span>
          <b-badge
            v-if="item.tag"
            pill
            :variant="item.tagVariant || 'primary'"
            class="mr-1 ml-auto"
          >
            {{ item.tag }}
          </b-badge>
        </div>
        <div class=" col-3" >
          <feather-icon style="width: 15px; height: 15px"
              icon="ChevronDownIcon" class="d-flex justify-content-end"/>
        </div>
        </b-link>
        
      </div>
      <b-collapse :id="'collapse-'+item.id" v-model="isOpen" class="menu-content" tag="ul">
        <component
          style="padding-left:10px"
          :is="resolveNavItemComponent(child)"
          v-for="child in item.children"
          :key="child.header || child.title"
          ref="groupChild"
          :item="child"
        />
      </b-collapse>
    </li>
  </div>
</template>

<script>
import { BLink, BBadge, BCollapse } from "bootstrap-vue";
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useUtils as useAclUtils } from "@core/libs/acl";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
// Composition Function
import mixinVerticalNavMenuGroup from "./mixinVerticalNavMenuGroup";
import useVerticalNavMenuLink from "../vertical-nav-menu-link/useVerticalNavMenuLink";
import useVerticalNavMenuGroup from "./useVerticalNavMenuGroup";
export default {
  name: "VerticalNavMenuGroup",
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isOpen, isActive, updateGroupOpen, updateIsActive } =
      useVerticalNavMenuGroup(props.item);
    const { linkProps } = useVerticalNavMenuLink(props.item);
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuGroup } = useAclUtils();
    
    return {
      resolveNavItemComponent,
      linkProps,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuGroup,

      // i18n
      t,
    };
  },
};
</script>

<style scoped>
.nav-item-col {
  transition: background-color 0.2s; /* Add a smooth transition effect to the background-color */
  padding: 0px 0px 0px 0px;
}
.nav-item-col :hover {
  background-color: rgba(0, 40, 110, 0.1);
}
.b-link-item {
  transition: background-color 0.2s; /* Add a smooth transition effect to the background-color */
}

.b-link-item:hover {
  background-color: rgba(0, 40, 110, 0.1); /* Change the background color on hover */
}
</style>
