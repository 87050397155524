<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      App. Version: 1.0.0.0
    </span>

    <span class="float-md-right d-none d-md-block">© Copyright 2023 NISO Software Technologies Inc. All rights reserved.
    </span>
  </p>
</template>
<style scoped>
  footer.footer span svg
  {
    color:#4a8cff
;  }
</style>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
