
export default [
  { header: " " },
  // {
  //   title: "Home",
  //   route: "PamisHome",
  //   icon: 'HomeIcon',
  // },
  /*
  { 
    name: 'Countries', 
    title: 'Country', 
    icon: 'FlagIcon', 
    route: { name: 'PamisCountry', params: { id: '100', name: 'Turkey' } } },
    */
    /*
  {
    title: 'Turkey',
    icon: 'LayersIcon',
    children: [
      { title: 'Plastic', icon:'TrelloIcon', children: [
        { name: 'PT7', title: 'PT7', icon: 'TrelloIcon', route: { name: 'PamisWorkcenter', params: { id: 100, name: 'PT7' } } },
        { name: 'PT9', title: 'PT9', icon: 'TrelloIcon', route: { name: 'PamisWorkcenter', params: { id: '101', name: 'PT9' } } },
        { name: 'PT11', title: 'PT11', icon: 'TrelloIcon', route: { name: 'PamisWorkcenter', params: { id: '102', name: 'PT11' } } },
        { name: 'PT12', title: 'PT12', icon: 'TrelloIcon', route: { name: 'PamisWorkcenter', params: { id: '103', name: 'PT12' } } },
        { name: 'PT13', title: 'PT13', icon: 'TrelloIcon', route: { name: 'PamisWorkcenter', params: { id: '104', name: 'PT13' } } },
        { name: 'PT15', title: 'PT15', icon: 'TrelloIcon', route: { name: 'PamisWorkcenter', params: { id: '105', name: 'PT15' } } },
        { name: 'PT19', title: 'PT19', icon: 'TrelloIcon', route: { name: 'PamisWorkcenter', params: { id: '106', name: 'PT19' } } },
        { name: 'PT20', title: 'PT20', icon: 'TrelloIcon', route: { name: 'PamisWorkcenter', params: { id: '107', name: 'PT20' } } },
        { name: 'PT23', title: 'PT23', icon: 'TrelloIcon', route: { name: 'PamisWorkcenter', params: { id: '108', name: 'PT23' } } },
        { name: 'PT24', title: 'PT24', icon: 'TrelloIcon', route: { name: 'PamisWorkcenter', params: { id: '109', name: 'PT24' } } },
        { name: 'PT27', title: 'PT27', icon: 'TrelloIcon', route: { name: 'PamisWorkcenter', params: { id: '110', name: 'PT27' } } },
        { name: 'PT44', title: 'PT44', icon: 'TrelloIcon', route: { name: 'PamisWorkcenter', params: { id: '111', name: 'PT44' } } },
        { name: 'PT63', title: 'PT63', icon: 'TrelloIcon', route: { name: 'PamisWorkcenter', params: { id: '112', name: 'PT63' } } },
      ], },
    ],
  },
  */
 
]
