<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items
      :items="[...navOrgList.filter((x) => x.visible == true)]"
    />
  </div>
</template>

<script>
import navMenuItems from "@/navigation/horizontal";
import HorizontalNavMenuItems from "./components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue";
import useAsyncHorizontalNavigation from "@/navigation/horizontal/useAsyncNavigation";
import { watch, ref } from "@vue/composition-api";
import { useRouter } from "@/@core/utils/utils";
export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    const { navOrgList } = useAsyncHorizontalNavigation();
    // Get the current route using useRoute
    const router = useRouter();
    const routerParams = ref(router.route.value);

    return {
      navOrgList,
      navMenuItems,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
