// eslint-disable-next-line object-curly-newline
import { ref, nextTick, watch, computed } from "@vue/composition-api";
// import store from '@/store'
import { isNavGroupActive } from "@core/layouts/utils";
import { useRouter } from "@/@core/utils/utils";
const openGroupList = ref([]);
export default function useHorizontalNavMenuGroup(item) {
  // ----------------------------------------
  // childDropdown
  // ----------------------------------------
  const route = useRouter();
  const refChildDropdown = ref(null);
  const openChildDropdownOnLeft = ref(false);
  // ----------------------------------------
  // isActive
  // ----------------------------------------
  watch(route.route, (newVal) => {
    //if(isOpen.value)
    //setTimeout(()=>isOpen.value=false,0)
  });

  const isOpen = ref(false);
  watch(isOpen, (newVal) => {
    if (!newVal) {
      const foundItemIndex = openGroupList.value.findIndex(
        (x) => x.id == item.id
      );
      openGroupList.value.splice(foundItemIndex, 1);
    }
  });
  watch(openGroupList, (newVal) => {
    const hasItemInList = newVal.find((x) => item.id == x.id);
    let hasSecondItemInList = [];
    if (!hasItemInList) return;
    hasSecondItemInList = newVal.filter((x) => x.level == item.level);
    if (hasSecondItemInList.length < 2) return;
    const oldSameLevelItem = hasSecondItemInList[0];
    if (oldSameLevelItem.id == item.id) {
      isOpen.value = false;
    }
  });

  const updateGroupOpen = (val) => {
    isOpen.value = val;
    if (val) {
      if (!openGroupList.value.find((x) => x.id == item.id)) {
        openGroupList.value.push({ id: item.id, level: item.level, paramId: item.paramId });
      }

      nextTick(() => {
        const childDropdownWidth = refChildDropdown.value.offsetWidth;
        const windowContentWidth = window.innerWidth - 16;
        const { left: childDropdownLeft } =
          refChildDropdown.value.getBoundingClientRect();
        const shallDropLeft =
          childDropdownLeft + childDropdownWidth - windowContentWidth;
        openChildDropdownOnLeft.value = shallDropLeft > 0;

        // Add scroll to child dd if don't have much space

        const refChildDropdownTop =
          refChildDropdown.value.getBoundingClientRect().top;
        const refChildDropdownHeight =
          refChildDropdown.value.getBoundingClientRect().height;
        if (
          window.innerHeight -
          refChildDropdownTop -
          refChildDropdownHeight -
          28 <
          1
        ) {
          const maxHeight = window.innerHeight - refChildDropdownTop - 70;
          refChildDropdown.value.style.maxHeight = `${maxHeight}px`;
          refChildDropdown.value.style.overflowY = "auto";
          refChildDropdown.value.style.overflowX = "hidden";
        }
      });
    } else {
      openChildDropdownOnLeft.value = false;
    }
  };

  // ------------------------------------------------
  // isActive
  // ------------------------------------------------
  const isActive = ref(false);

  const updateIsActive = () => {
    isActive.value = isNavGroupActive(item.children);
  };

  return {
    isOpen,
    isActive,
    updateGroupOpen,
    updateIsActive,
    refChildDropdown,
    openChildDropdownOnLeft,
  };
}
