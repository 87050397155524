<template>
  <li
    v-if="canViewHorizontalNavMenuHeaderGroup(item)"
    class="dropdown nav-item"
    :id="item.id"
    :class="{
      'sidebar-group-active active open': isActive,
      show: isOpen,
    }"
    ref="list"
    @mouseenter="() => updateGroupOpen(true)"
    @mouseleave="() => updateGroupOpen(false)"
  >
    <b-link
      :id="'link-' + item.id"
      class="nav-link dropdown-toggle d-flex align-items-center"
    >
      <feather-icon size="14" :icon="item.icon" v-bind="linkProps" />
      <span>{{ t(item.header) }}</span>
    </b-link>
    <ul class="dropdown-menu" :id="item.id">
      <component
        :is="resolveHorizontalNavMenuItemComponent(child)"
        v-for="child in item.children"
        :key="child.title"
        :item="child"
      />
    </ul>
  </li>
</template>

<script>
import { BLink } from "bootstrap-vue";
import { resolveHorizontalNavMenuItemComponent } from "@core/layouts/utils";
import { useUtils as useAclUtils } from "@core/libs/acl";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useHorizontalNavMenuHeaderGroup from "./useHorizontalNavMenuHeaderGroup";
import mixinHorizontalNavMenuHeaderGroup from "./mixinHorizontalNavMenuHeaderGroup";
import { onClickOutside } from "@vueuse/core";
import { ref } from "@vue/composition-api";
import HorizontalNavMenuGroup from "../horizontal-nav-menu-group/HorizontalNavMenuGroup.vue";
import HorizontalNavMenuLink from "../horizontal-nav-menu-link/HorizontalNavMenuLink.vue";
import useHorizontalNavMenuLink from "../horizontal-nav-menu-link/useHorizontalNavMenuLink";
export default {
  components: {
    BLink,
    HorizontalNavMenuGroup,
    HorizontalNavMenuLink,
  },
  mixins: [mixinHorizontalNavMenuHeaderGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, updateIsActive, isOpen, updateGroupOpen } =
      useHorizontalNavMenuHeaderGroup(props.item);
    const { t } = useI18nUtils();
    const { canViewHorizontalNavMenuHeaderGroup } = useAclUtils();
    const { linkProps } = useHorizontalNavMenuLink(props.item);
    const list = ref(null);
    onClickOutside(list, (event) => {
      updateGroupOpen(false);
    });
    return {
      list,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
      linkProps,
      resolveHorizontalNavMenuItemComponent,

      // ACL
      canViewHorizontalNavMenuHeaderGroup,

      // i18n
      t,
    };
  },
};
</script>
